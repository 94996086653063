import React from 'react';
import { graphql } from 'gatsby';
import TitlePage from '../components/TitlePage';
import SEO from '../components/seo';
import Container from '../components/Container'
import RichText from '../components/RichText'
import * as S from './page.styled';


const Page = props => {
  const post = props.data.markdownRemark;

  return (
    <Container>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />

      <S.PageWrap>
        <TitlePage text={post.frontmatter.title} />
        <S.PageIntro>{post.frontmatter.description}</S.PageIntro>
        <RichText dangerouslySetInnerHTML={{ __html: post.html }} />
      </S.PageWrap>


    </Container>
  );
};

export const query = graphql`
  query Page($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        image
      }
      html
    }
  }
`;

export default Page;
