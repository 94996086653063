import styled from 'styled-components';

export const PageWrap = styled.div`
   max-width: 886px;
`;

export const PageIntro = styled.p`
  color: var(--text-color);
  font-weight: bold;
  padding-bottom: var(--space);
`;

